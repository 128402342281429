import logo from './TCCentral_DarkBlueBack.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p><a className="App-link" href="mailto:info@tccentral.com">info@tccentral.com</a>
        </p>

      </header>
    </div>
  );
}

export default App;
